<template>
  <td class="table-actions d-flex align-center" align="center">
    <v-tooltip top v-if="hasEdit" v-show="can_edit" color="primary">
      <template v-slot:activator="{ on }">
        <v-btn dense v-on="on" icon @click="handle_action('edit')">
          <v-icon color="primary">{{ editIcon }}</v-icon>
        </v-btn>
      </template>
      <span> {{ editTips }} </span>
    </v-tooltip>
    <v-tooltip top v-if="hasDelete" v-show="can_delete" color="primary">
      <template v-slot:activator="{ on }">
        <v-btn dense v-on="on" icon @click="handle_action('delete')">
          <v-icon color="primary">{{ deleteIcon }}</v-icon>
        </v-btn>
      </template>
      <span> {{ deleteTips }} </span>
    </v-tooltip>
    <v-tooltip top v-if="hasView" v-show="can_view" color="primary">
      <template v-slot:activator="{ on }">
        <v-btn dense v-on="on" icon @click="handle_action('view')">
          <v-icon color="primary">{{ viewIcon }}</v-icon>
        </v-btn>
      </template>
      <span> {{ viewTips }} </span>
    </v-tooltip>
    <slot name="extra" />
  </td>
</template>
<script>
export default {
  props: {
    item: { type: Object, default: null },
    permissions: {
      type: Object,
      default: () => ({
        delete: true,
        update: true,
        create: true,
        view: true
      })
    },
    editTips: { type: String, default: 'Edit' },
    viewTips: { type: String, default: 'View' },
    deleteTips: { type: String, default: 'Delete' },
    editIcon: { type: String, default: 'mdi-pencil' },
    viewIcon: { type: String, default: 'mdi-eye' },
    deleteIcon: { type: String, default: 'mdi-delete' },
    hasDelete: { type: Boolean, default: true },
    hasEdit: { type: Boolean, default: true },
    hasView: { type: Boolean, default: true }
  },
  computed: {
    logged_user() {
      return this.$store.getters.user
    }
  },
  methods: {
    handle_action(event) {
      if (this.item) {
        this.$emit(event, this.item)
      }
    },
    can_delete() {
      if (this.logged_user.is_admin) {
        return true
      }
      return this.permissions && this.permissions.delete
    },
    can_edit() {
      if (this.logged_user.is_admin) {
        return true
      }
      return this.permissions && this.permissions.update
    },
    can_view() {
      if (this.logged_user.is_admin) {
        return true
      }
      return this.permissions && this.permissions.view
    }
  }
}
</script>
<style lang="scss"></style>
